import { faPencil, faPlus } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

import { getIconTranslationString } from '../../../CommentIconContent/CommentIconContent.utils';
import CommentHeader from '../../../generic/CommentHeader/CommentHeader';
import UserAvatar from '../../../User/UserAvatar';
import { getProfileName, getUserById } from '../../../User/utils';
import TopBarDateItem from './TopBarDateItem';
import TopBarDueDate from './TopBarDueDate';
import styles from './TopBarLabelList.module.css';
import { useAppSelector } from 'src/store';

import type { Ticket } from '../../../../types/Ticket';

interface Props {
  task: Ticket;
  showWeight?: boolean;

  dueDateChange: (newDueDate: number) => void;
  updateTicket(id: string, data: Partial<Ticket>): void;
}

const TopBarLabelList: FC<Props> = ({
  task,
  // showWeight,
  dueDateChange
  // updateTicket
}) => {
  const { t } = useTranslation();
  // const channels = useSelector((state: State) => state.channels);
  // const [weight, setWeight] = useState(task?.weight || 0);
  // const [isEditingWeight, setIsEditingWeight] = useState(false);
  const users = useAppSelector((state) => state.usersList.usersList);
  const user = getUserById(users, task.createdByUser);
  const name = getProfileName(user);

  if (!task) {
    return null;
  }

  return (
    <div className={styles.topBarLabelListContainer}>
      <CommentHeader
        avatar={<UserAvatar UID={task.createdByUser} size="40" round />}
        name={name}
        extra={`via ${t(getIconTranslationString(task.channel))}`}
        description={task.lastContactAddress ? `To: ${task.lastContactAddress}` : ''}
        appearence="ticketHeader"
      />

      <div className={styles.topBarDates}>
        <TopBarDateItem value={task?.created} translationKey={'TOOLTIP_CREATED'} icon={faPlus} />
        <TopBarDateItem value={task?.touched} translationKey={'TOOLTIP_TOUCHED'} icon={faPencil} />
        <TopBarDueDate dateDue={task?.dueDate} dueDateChange={dueDateChange} />
      </div>

      {/* {false && (
        <>
          <List className="topBarLabelList" horizontal divided>
            {channels && 'originalDirection' in task && (
              <Popup
                disabled
                position="bottom center"
                trigger={
                  <List.Item>
                    <Label>
                      <Icon
                        name={iconName(
                          channels.find((c) => c.id === task.channel),
                          task.originalDirection
                        )}
                      />
                      {t(`TICKET_DIRECTION_${task.originalDirection.toUpperCase()}`)}
                    </Label>
                  </List.Item>
                }
              />
            )}

            {showWeight && (
              <WeightModal
                weight={weight}
                onChange={(value) => {
                  setWeight(value);
                  if (task) {
                    updateTicket(task.id, {
                      weight: value
                    });
                  }
                }}
                open={isEditingWeight}
                setOpen={setIsEditingWeight}
              />
            )}
          </List>
        </>
      ) 
      }*/}
    </div>
  );
};

export default React.memo(TopBarLabelList);
