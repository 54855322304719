import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import type { TranscriptionMessage } from 'src/Components/Case/Widget/transcriptionsWidgetTypes';

export interface TranscriptionReducerType {
  transcriptionsByContentId: {
    [key: string]: TranscriptionMessage[];
  };
}

const initialState: TranscriptionReducerType = {
  transcriptionsByContentId: {}
};

interface TranscriptionPayload {
  contentId: string;
  message: TranscriptionMessage;
}

const transcriptionMessagesSlice = createSlice({
  name: 'transcriptionMessages',
  initialState,
  reducers: {
    transcriptionsUpdated(state, action: PayloadAction<TranscriptionPayload | TranscriptionPayload[]>) {
      const messages = Array.isArray(action.payload) ? action.payload : [action.payload];
      messages.forEach((message: TranscriptionPayload) => {
        const transcriptionsByContentId = state.transcriptionsByContentId[message.contentId];
        if (!transcriptionsByContentId) {
          state.transcriptionsByContentId[message.contentId] = [];
        }
        state.transcriptionsByContentId[message.contentId].push(message.message);
      });
    }
  }
});

export const transcriptionsUpdated = transcriptionMessagesSlice.actions.transcriptionsUpdated;

export default transcriptionMessagesSlice.reducer;
