import { faCalendar, faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import { enUS } from 'date-fns/locale';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import moment from 'moment';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, DateRange, DateRangePicker } from 'react-date-range';
import { useTranslation } from 'react-i18next';

import type { CalendarProps, DateRangePickerProps, DateRangeProps } from 'react-date-range';

import { getLocale } from '../utils/dateUtils';
import Button from './generic/Button/Button';
import Popup from './generic/Popup/Popup';
import Section from './generic/Section/Section';
import TimeInput from './ticketList/SingleLineTicketView/TimeInput';

import type { PopupPosition } from 'src/types/PopupTypes';

import './DateTimePicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

type OnDateRangeChange = NonNullable<DateRangeProps['onChange']>;
type CommonProps = Pick<
  DateRangePickerProps,
  'color' | 'months' | 'direction' | 'rangeColors' | 'showMonthAndYearPickers' | 'locale'
>;

interface DateTimePickerProps {
  color?: string;
  isRange?: boolean;
  isTimePicker?: boolean;
  isShortCuts?: boolean;
  isClearable?: boolean;
  months?: CalendarProps['months'];
  minDate?: moment.Moment;
  dateFormat?: string;
  startDate?: number | null;
  endDate?: number | null;
  position?: PopupPosition;
  label?: string;
  trigger?: (props: { setIsOpen: (isOpen: boolean) => void }) => React.ReactNode;
  onChange?: ({ date, range }: onChangeProps) => void;
  onClose?: () => void;
}

type onChangeProps = {
  date: number | null;
  range?: { startDate?: number; endDate?: number };
};

type Range = { startDate?: Date; endDate?: Date };

const DateTimePicker = ({
  isRange = false,
  isTimePicker = false,
  isShortCuts = false,
  months = 1,
  position = 'bottom center',
  color = '#7F56DA',
  startDate: initialStartDate,
  endDate: initialEndDate,
  trigger,
  minDate,
  dateFormat: initialDateFormat,
  label,
  onChange
}: DateTimePickerProps) => {
  const { t, i18n } = useTranslation();

  const formatDate = initialDateFormat ? initialDateFormat : t('DATEFORMAT_FULL');
  const formatDateTime = `${formatDate} HH:mm`;

  const [isOpen, setIsOpen] = useState(false);
  const [renderDateValue, setRenderDateValue] = useState<string>('');
  const [range, setRange] = useState<Range>({
    startDate: initialStartDate ? moment.unix(initialStartDate).toDate() : undefined,
    endDate: initialEndDate ? moment.unix(initialEndDate).toDate() : undefined
  });

  const dateFormat = useMemo(() => (isTimePicker ? formatDateTime : formatDate), [isTimePicker]);

  const { startDate, endDate } = range;

  const onDateRangeChange = useCallback<OnDateRangeChange>(
    (ranges) => {
      const { startDate, endDate } = ranges['selection'] ?? {};
      setRange({ startDate, endDate: endDate ? setMinutes(setHours(endDate, 23), 59) : endDate });
      handleOnChange();
    },
    [setRange]
  );

  const onChangeDate = useCallback(
    (date: Date) => {
      setRange({ startDate: date });
      handleOnChange();
    },
    [endDate, setRange]
  );

  const handleOnChange = () => {
    onChange &&
      onChange({
        date: startDate ? moment(startDate).unix() : null,
        range: {
          startDate: startDate ? moment(startDate).unix() : undefined,
          endDate: endDate ? moment(endDate).unix() : undefined
        }
      });
  };

  const handleResetRange = () => {
    setRange({
      startDate: initialStartDate ? moment.unix(initialStartDate).toDate() : undefined,
      endDate: initialEndDate ? moment.unix(initialEndDate).toDate() : undefined
    });
  };

  const clearDate = useCallback(() => {
    setRange({ startDate: undefined, endDate: undefined });
  }, [setRange]);

  const onStartTimeChange = useCallback(
    (date: Date) => {
      setRange({ startDate: date, endDate });
    },
    [endDate, setRange]
  );
  const onEndTimeChange = useCallback(
    (date: Date) => {
      setRange({ startDate, endDate: date });
    },
    [startDate, setRange]
  );

  const renderCalendar = useMemo(() => {
    const commonProps: CommonProps = {
      locale: getLocale(i18n.language),
      color,
      months,
      direction: 'horizontal',
      rangeColors: [color],
      showMonthAndYearPickers: false
    };

    if (isRange && isShortCuts) {
      return (
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          onChange={(range) => onDateRangeChange(range)}
          minDate={minDate?.toDate()}
          {...commonProps}
        />
      );
    }
    if (isRange) {
      return (
        <DateRange
          locale={enUS}
          ranges={[{ startDate, endDate, key: 'selection' }]}
          onChange={(range) => onDateRangeChange(range)}
          {...commonProps}
        />
      );
    }
    return <Calendar locale={enUS} date={startDate} onChange={(date) => onChangeDate(date)} {...commonProps} />;
  }, [startDate, endDate, onDateRangeChange, color, months, isRange, isShortCuts]);

  // change the inputValue on date change
  useEffect(() => {
    if (startDate && endDate) {
      setRenderDateValue(`${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`);
    } else if (startDate) {
      setRenderDateValue(format(startDate, dateFormat));
    } else {
      setRenderDateValue(t('SELECT_DATE'));
    }
  }, [startDate, endDate, dateFormat]);

  return (
    <Popup
      on="click"
      noPadding
      open={isOpen}
      position={position}
      onClose={() => setIsOpen(false)}
      trigger={
        trigger?.({ setIsOpen }) || (
          <div>
            {label ? <label>{label}</label> : null}
            <Button
              fullWidth
              type="normal"
              onClick={() => setIsOpen(true)}
              iconLeft={<FontAwesomeIcon icon={faCalendar} />}
              iconRight={startDate ? <FontAwesomeIcon icon={faClose} onClick={clearDate} /> : undefined}
              content={renderDateValue}
            />
          </div>
        )
      }
    >
      <div className="datePickerWrap">
        <Section direction="column" gap={8}>
          {renderCalendar}
          {isTimePicker && (
            <TimeInputComponent
              startDate={startDate}
              endDate={endDate}
              onStartTimeChange={onStartTimeChange}
              onEndTimeChange={onEndTimeChange}
              startTimeLabel={t('DATEPICKER_START_TIME')}
              endTimeLabel={t('DATEPICKER_END_TIME')}
            />
          )}
        </Section>
      </div>
      <Section gap={8} direction="row" className="datePickerWrap-footer">
        <Button
          content={t('GENERAL_SELECT')}
          type="primary"
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();

            handleOnChange();
            setIsOpen(false);
          }}
        />
        <Button
          content={t('GENERAL_RESET')}
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();

            handleResetRange();
            handleOnChange();
          }}
        />
      </Section>
    </Popup>
  );
};

interface TimeInputComponentProps {
  startDate?: Date;
  endDate?: Date;
  onStartTimeChange: (time: Date) => void;
  onEndTimeChange: (time: Date) => void;
  startTimeLabel: string;
  endTimeLabel: string;
}

const TimeInputComponent = React.memo(
  ({
    startDate,
    endDate,
    startTimeLabel,
    endTimeLabel,
    onStartTimeChange,
    onEndTimeChange
  }: TimeInputComponentProps) => (
    <Section direction="row" justify="space-between" gap={20} padding="0 0.833em 0 0.833em">
      {startDate && (
        <Section direction="column" className="TimeInput" gap={4}>
          <label>{startTimeLabel}:</label>
          <TimeInput onChange={(time) => onStartTimeChange(time)} date={startDate} />
        </Section>
      )}
      {endDate && (
        <Section direction="column" className="TimeInput" gap={4}>
          <label>{endTimeLabel}:</label>
          <TimeInput
            onChange={(time) => onEndTimeChange(time)}
            date={endDate}
            shiftSeconds="59"
            minTime={startDate ? format(startDate, 'HH:mm') : undefined}
          />
        </Section>
      )}
    </Section>
  )
);

export default React.memo(DateTimePicker);
